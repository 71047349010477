const ALERTS_CLEAR_LIST = "ALERTS_CLEAR_LIST";
const ALERTS_SET_LIST = "ALERTS_SET_LIST";
const DETAIL_SHOW_ERROR = "DETAIL_SHOW_ERROR";
const LOAD_LITERALS = "LOAD_LITERALS";
const NOTICATIONS_SET_LIST = "NOTICATIONS_SET_LIST";
const RESET_ERROR = "RESET_ERROR";
const RESET_SORT_BY_AND_ORDER = "SORT_RESET_SORT_BY_AND_ORDER";
const SCREEN_SHOW_ERROR = "PAGE_SHOW_ERROR";
const SET_CURRENT_IFRAME_SOURCE = "SET_CURRENT_IFRAME_SOURCE";
const SHOW_SORT_MENU = "SHOW_SORT_MENU";
const SORT_CLEAR_STATE = "SORT_CLEAR_STATE";
const SORT_SET_OPTIONS = "SORT_SET_OPTIONS";
const UPDATE_SORTDETAILS = "SORT_UPDATE_SORTDETAILS";
const UPDATE_SORTORDER = "SORT_UPDATE_SORTORDER";

const SET_SELECTED_LIST_ITEM = "SET_SELECTED_LIST_ITEM";
const SET_DETAIL_VIEW_FETCHING_STATE = "SET_DETAIL_VIEW_FETCHING_STATE";
const SET_DETAIL_VIEW_EDITABLE = "SET_DETAIL_VIEW_EDITABLE";
const SET_FIRST_FETCH_STATE = "SET_FIRST_FETCH_STATE";
const SET_FETCHING_STATE = "SET_FETCHING_STATE";
const SET_FILTER_DATA = "SET_FILTER_DATA";
const SET_LIST_DATA = "SET_LIST_DATA";
const SET_SHOW_BUTTON_LOADER = "SET_SHOW_BUTTON_LOADER";
const SET_LOAD_MORE = "SET_LOAD_MORE";
const SET_PAGE_NUMBER = "SET_PAGE_NUMBER";
const SET_TOTAL_COUNT = "SET_TOTAL_COUNT";
const SET_INTITAL_FETCH_STATE = "SET_INTITAL_FETCH_STATE";
const RESET_COMMON_DATA = "RESET_COMMON_DATA";

const NAVBAR_GET_LIST = "NAVBAR_GET_LIST";
const NAVBAR_SET_EXPANDED_ITEM = "NAVBAR_SET_EXPANDED_ITEM";
const NAVBAR_TOGGLE_STATE = "NAVBAR_TOGGLE_STATE";
const NAVBAR_SHOW = "NAVBAR_SHOW";

const APPBAR_SET_DESKTOP_BACK_BUTTON = "APPBAR_SET_DESKTOP_BACK_BUTTON";
const APPBAR_COMPONENT = "APPBAR_COMPONENT";
const APPBAR_SET_SHOW_LOGO_HIDE_COMPONENT =
  "APPBAR_SET_SHOW_LOGO_HIDE_COMPONENT";
const APPBAR_SET_BACK_BUTTON = "APPBAR_SET_BACK_BUTTON";
const APPBAR_SET_IS_PROGRESS_BAR_LOADING = "APPBAR_SET_IS_PROGRESS_BAR_LOADING";

const MULTI_DRAWER_PUSH = "MULTI_DRAWER_PUSH";
const MULTI_DRAWER_POP = "MULTI_DRAWER_POP";
const MULTI_DRAWER_RESET = "MULTI_DRAWER_RESET";
const SET_REFRESH_MULTI_DRAWER = "SET_REFRESH_MULTI_DRAWER";
const SET_CAN_CLOSE_MULTI_DRAWER = "SET_CAN_CLOSE_MULTI_DRAWER";
const UPDATE_MULTI_DRAWER = "UPDATE_MULTI_DRAWER";
const UPDATE_MULTI_DRAWER_ACTIONS = "UPDATE_MULTI_DRAWER_ACTIONS";
const RESET_MULTI_DRAWER_ACTIONS = "RESET_MULTI_DRAWER_ACTIONS";
const UPDATE_MULTI_DRAWER_EXTRA_ACTIONS = "UPDATE_MULTI_DRAWER_EXTRA_ACTIONS";

const DRAWER_SET_STATE = "DRAWER_SET_STATE";
const DRAWER_SET_COMPONENT = "DRAWER_SET_COMPONENT";
const DRAWER_RESET = "DRAWER_RESET";
const DRAWER_SET_TITLE = "DRAWER_SET_TITLE";
const DRAWER_SET_ACTIONS = "DRAWER_SET_ACTIONS";
const DRAWER_SET_EXTRA_ACTIONS = "DRAWER_SET_EXTRA_ACTIONS";

const HIDE_SHOW_APPBAR_NAVBAR = "HIDE_SHOW_APPBAR_NAVBAR";
const SET_LOADER = "SET_LOADER";
const SET_MOB_RESPONSE = "SET_MOB_RESPONSE";
const SET_CHECKOUT_PAGE_EMAIL="SET_CHECKOUT_PAGE_EMAIL"
export default {
  ALERTS_CLEAR_LIST,
  ALERTS_SET_LIST,
  DETAIL_SHOW_ERROR,
  LOAD_LITERALS,
  NOTICATIONS_SET_LIST,
  RESET_ERROR,
  RESET_SORT_BY_AND_ORDER,
  SCREEN_SHOW_ERROR,
  SET_CURRENT_IFRAME_SOURCE,
  SHOW_SORT_MENU,
  SORT_CLEAR_STATE,
  SORT_SET_OPTIONS,
  UPDATE_SORTDETAILS,
  UPDATE_SORTORDER,

  SET_SELECTED_LIST_ITEM,
  SET_DETAIL_VIEW_FETCHING_STATE,
  SET_DETAIL_VIEW_EDITABLE,
  SET_FIRST_FETCH_STATE,
  SET_FETCHING_STATE,
  SET_FILTER_DATA,
  SET_LIST_DATA,
  SET_SHOW_BUTTON_LOADER,
  SET_LOAD_MORE,
  SET_TOTAL_COUNT,
  SET_INTITAL_FETCH_STATE,
  SET_PAGE_NUMBER,
  RESET_COMMON_DATA,

  NAVBAR_GET_LIST,
  NAVBAR_SET_EXPANDED_ITEM,
  NAVBAR_TOGGLE_STATE,
  NAVBAR_SHOW,

  APPBAR_COMPONENT,
  APPBAR_SET_DESKTOP_BACK_BUTTON,
  APPBAR_SET_SHOW_LOGO_HIDE_COMPONENT,
  APPBAR_SET_BACK_BUTTON,
  APPBAR_SET_IS_PROGRESS_BAR_LOADING,

  MULTI_DRAWER_PUSH,
  MULTI_DRAWER_POP,
  MULTI_DRAWER_RESET,
  SET_REFRESH_MULTI_DRAWER,
  SET_CAN_CLOSE_MULTI_DRAWER,
  UPDATE_MULTI_DRAWER,
  UPDATE_MULTI_DRAWER_ACTIONS,
  RESET_MULTI_DRAWER_ACTIONS,
  UPDATE_MULTI_DRAWER_EXTRA_ACTIONS,

  DRAWER_SET_STATE,
  DRAWER_SET_COMPONENT,
  DRAWER_SET_TITLE,
  DRAWER_RESET,
  DRAWER_SET_ACTIONS,
  DRAWER_SET_EXTRA_ACTIONS,

  HIDE_SHOW_APPBAR_NAVBAR,
  SET_LOADER,
  SET_MOB_RESPONSE,
  SET_CHECKOUT_PAGE_EMAIL,
};
