import ACTION_TYPES from "./commonActionTypes";

export function sortHandleClick(event) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SHOW_SORT_MENU,
      payload: event.currentTarget,
    });
  };
}

export function sortHandleClose() {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SHOW_SORT_MENU,
      payload: null,
    });
  };
}

export function updateSortOrder(sortOrder) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_SORTORDER,
      payload: sortOrder,
    });
  };
}

export function updateSortDetails(sortBy, appliedSortIndex, sortOrder) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_SORTDETAILS,
      payload: {
        sortBy,
        appliedSortIndex,
        sortOrder,
      },
    });
  };
}

export function clearSortComponentState() {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SORT_CLEAR_STATE,
    });
  };
}

export function setSortOptions(sortOptions) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SORT_SET_OPTIONS,
      payload: sortOptions,
    });
  };
}

export function resetSortByAndOrder() {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.RESET_SORT_BY_AND_ORDER,
    });
  };
}
