import ACTION_TYPES from "./commonActionTypes";
import { load, getConstants } from "../../assets/literals/index";

export function loadLiterals(lang, routeName) {
  return (dispatch) => {
    load(lang, routeName).then((response) => {
      // response is a JSON of literals in the requested route (routeName)
      dispatch({
        type: ACTION_TYPES.LOAD_LITERALS,
        payload: {
          key: routeName,
          literals: response.default,
        },
      });
    });
  };
}

export function loadConstants(lang) {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.LOAD_LITERALS,
      payload: {
        key: "constants",
        literals: getConstants(lang),
      },
    });
  };
}
