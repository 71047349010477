import ACTION_TYPES from "./commonActionTypes";
import store from "../../../store";
import {
  deleteNotificationTimeout,
  removeNotificationTimeout,
} from "../../constants/notificationConstants";

function push(type, message, title, id, canRemove = true) {
  const currentNotificationsList = store.getState().notification.notifications;

  for (let [key, value] of currentNotificationsList) {
    if (value.message == message) {
      id = key;
    }
  }

  const notifications = new Map();
  const notificationId = id || currentNotificationsList.size + 1;
  notifications.set(notificationId, {
    type,
    message,
    title,
    isActive: true,
    canRemove,
  });
  const newNotificationsList = new Map([
    ...currentNotificationsList,
    ...notifications,
  ]);

  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.NOTICATIONS_SET_LIST,
      payload: newNotificationsList,
    });
    if (canRemove) {
      setTimeout(() => {
        dispatch(removeNotification(notificationId));
      }, removeNotificationTimeout);
      setTimeout(() => {
        dispatch(deleteNotification(notificationId));
      }, deleteNotificationTimeout);
    }
  };
}
function removeNotification(notificationId) {
  const notificationsList = store.getState().notification.notifications;
  const removeNotificationsList = new Map([...notificationsList, ...new Map()]);
  if (removeNotificationsList.has(notificationId)) {
    const currentNotification = removeNotificationsList.get(notificationId);
    currentNotification.isActive = false;
    removeNotificationsList.set(notificationId, currentNotification);
  }
  return {
    type: ACTION_TYPES.NOTICATIONS_SET_LIST,
    payload: removeNotificationsList,
  };
}

function deleteNotification(notificationId) {
  const notificationsList = store.getState().notification.notifications;
  const removeNotificationsList = new Map([...notificationsList, ...new Map()]);
  if (removeNotificationsList.has(notificationId)) {
    removeNotificationsList.delete(notificationId);
  }
  return {
    type: ACTION_TYPES.NOTICATIONS_SET_LIST,
    payload: removeNotificationsList,
  };
}

export const notificationActions = {
  push,
  deleteNotification,
  removeNotification,
};
