import ACTION_TYPES from "./commonActionTypes";

export function pushMultiDrawerItem(drawerItem) {
  return {
    type: ACTION_TYPES.MULTI_DRAWER_PUSH,
    payload: drawerItem,
  };
}

export function popMultiDrawerItem() {
  return {
    type: ACTION_TYPES.MULTI_DRAWER_POP,
  };
}

export function resetMultiDrawer() {
  return {
    type: ACTION_TYPES.MULTI_DRAWER_RESET,
  };
}

export function setRefreshMultiDrawer(refresh) {
  return {
    type: ACTION_TYPES.SET_REFRESH_MULTI_DRAWER,
    payload: refresh,
  };
}

export function setCanCloseDrawer(canCloseDrawer) {
  return {
    type: ACTION_TYPES.SET_CAN_CLOSE_MULTI_DRAWER,
    payload: canCloseDrawer,
  };
}

export function updateDrawer(drawer, index) {
  return {
    type: ACTION_TYPES.UPDATE_MULTI_DRAWER,
    payload: { drawer, index },
  };
}

export function updateDrawerActions(drawerActions, index) {
  return {
    type: ACTION_TYPES.UPDATE_MULTI_DRAWER_ACTIONS,
    payload: { drawerActions, index },
  };
}

export function resetDrawerActions(index) {
  return {
    type: ACTION_TYPES.RESET_MULTI_DRAWER_ACTIONS,
    payload: { index },
  };
}

export function updateDrawerExtraActions(drawerActions, index) {
  return {
    type: ACTION_TYPES.UPDATE_MULTI_DRAWER_EXTRA_ACTIONS,
    payload: { drawerActions, index },
  };
}
