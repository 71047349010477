import ACTION_TYPES from "./commonActionTypes";
import { getNavigation } from "../../api/navigation";
import { retry } from "../../utils/componentUtil";

export function getNavigationList(errorCallback, successCallback) {
  return (dispatch) => {
    retry(() => getNavigation(), 3)
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.NAVBAR_GET_LIST,
          payload: response,
        });
        dispatch(setShowNavBar(true));
        successCallback && successCallback();
      })
      .catch(() => {
        dispatch(setShowNavBar(false));
        errorCallback && errorCallback();
      });
  };
}

export function setExpandedItem(itemIndex) {
  return {
    type: ACTION_TYPES.NAVBAR_SET_EXPANDED_ITEM,
    payload: itemIndex,
  };
}

export function toggleNavbar() {
  return {
    type: ACTION_TYPES.NAVBAR_TOGGLE_STATE,
  };
}

export function setShowNavBar(show) {
  return {
    type: ACTION_TYPES.NAVBAR_SHOW,
    payload: show,
  };
}
