import ACTION_TYPES from "./commonActionTypes";

export function showDrawer() {
  return {
    type: ACTION_TYPES.DRAWER_SET_STATE,
    payload: true,
  };
}

export function setDrawerTitle(title) {
  return {
    type: ACTION_TYPES.DRAWER_SET_TITLE,
    payload: title,
  };
}

export function hideDrawer() {
  return {
    type: ACTION_TYPES.DRAWER_SET_STATE,
    payload: false,
  };
}

export function setDrawerComponent(payload) {
  return {
    type: ACTION_TYPES.DRAWER_SET_COMPONENT,
    payload,
  };
}

export function resetDrawerComponent() {
  return {
    type: ACTION_TYPES.DRAWER_RESET,
  };
}

export function setDrawerClearButtonState(showClearButton) {
  return {
    type: ACTION_TYPES.DRAWER_SET_CLEAR_STATE,
    payload: showClearButton,
  };
}

export function setDrawerClearAction(action) {
  return {
    type: ACTION_TYPES.DRAWER_SET_CLEAR_ACTION,
    payload: action,
  };
}

export function setDrawerActions(actions) {
  return {
    type: ACTION_TYPES.DRAWER_SET_ACTIONS,
    payload: actions,
  };
}

export function setDrawerExtraActions(actions) {
  return {
    type: ACTION_TYPES.DRAWER_SET_EXTRA_ACTIONS,
    payload: actions,
  };
}
